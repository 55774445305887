<!--
 * @Description: 余额变动报表列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-detail-plus class="box-card">
        <div class="flexBox">
          <span class="el-col el-col-4">总计</span>
          <ykc-detail-item-plus label="消费金额" :labelWidth="100">
            <span>{{ dealData(amountInfo.totalOrderAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="微信充值" :labelWidth="100">
            <span>{{ dealData(amountInfo.wechatRechargeAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="后台充值" :labelWidth="100">
            <span>{{ dealData(amountInfo.platformRechargeAmount) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <span class="el-col el-col-4">平均</span>
          <ykc-detail-item-plus label="消费金额" :labelWidth="100">
            <span>{{ dealData(amountInfo.totalOrderAvgAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="微信充值" :labelWidth="100">
            <span>{{ dealData(amountInfo.wechatRechargeAvgAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="后台充值" :labelWidth="100">
            <span>{{ dealData(amountInfo.platformRechargeAvgAmount) }}</span>
          </ykc-detail-item-plus>
        </div>
      </ykc-detail-plus>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'report:list:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>
<script>
  import { reportforms } from '@/service/apis';
  import { offlineExport, dealData } from '@/utils';
  // import { cloneDeep } from '../../../../utils';

  export default {
    name: 'publicPile',
    components: {},
    data() {
      return {
        searchParams: {
          userAccount: '',
          startTime: '',
          endTime: '',
        },
        amountInfo: {}, // 统计
        // 列表表格
        tableTitle: '用户余额变动报表',
        tableData: [],
        tableColumns: [
          { label: '用户账号', prop: 'userAccount', minWidth: '150px' },
          {
            label: '开始记账⽇期',
            prop: 'startTime',
            minWidth: '150px',
          },
          {
            label: '结束记账⽇期',
            prop: 'endTime',
            minWidth: '150px',
          },
          { label: '期初账户余额', prop: 'beginAccountAmount', minWidth: '150px' },

          { label: '期初抵⽤卡余额', prop: 'beginVoucherAmount', minWidth: '150px' },
          {
            label: '微信充值',
            prop: 'wechatRechargeAmount',
            minWidth: '150px',
          },
          {
            label: '后台充值',
            prop: 'platformRechargeAmount',
            minWidth: '150px',
          },
          {
            label: '余额退款',
            prop: 'refundAmount',
            minWidth: '150px',
          },

          {
            label: '抵⽤卡金额',
            prop: 'voucherAmount',
            minWidth: '150px',
          },
          {
            label: '抵⽤卡到期⾦额',
            prop: 'voucherExpireAmount',
            minWidth: '200px',
          },
          {
            label: '订单⾦额(公桩)',
            prop: 'totalOrderAmount',
            minWidth: '150px',
          },
          {
            label: '订单⾦额(私桩)',
            prop: 'totalPrivateOrderAmount',
            minWidth: '150px',
          },
          {
            label: '优惠券红包抵扣金额',
            prop: 'couponAmount',
            minWidth: '150px',
          },
          {
            label: '抵⽤卡使⽤⾦额(公桩)',
            prop: 'voucherUseAmount',
            minWidth: '150px',
          },
          {
            label: '抵⽤卡使⽤⾦额(私桩)',
            prop: 'voucherPrivateUseAmount',
            minWidth: '150px',
          },
          {
            label: '支付分金额(公桩)',
            prop: 'wechatGradeAmount',
            minWidth: '150px',
          },
          {
            label: '支付分金额(私桩)',
            prop: 'wechatGradePrivateAmount',
            minWidth: '150px',
          },
          {
            label: '消费订单退款',
            prop: 'platformRefundAmount',
            minWidth: '150px',
          },
          {
            label: '期末账户余额',
            prop: 'endAccountAmount',
            minWidth: '150px',
          },
          {
            label: '期末抵⽤卡余额',
            prop: 'endVoucherAmount',
            minWidth: '150px',
          },
          {
            label: '⽤户商户',
            prop: 'mchName',
            minWidth: '200px',
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcDatePicker',
            key: 'times',
            label: '记账⽇期区间',
            placeholder: '请选择记账⽇期区间',
          },
        ];
      },
    },
    mounted() {
      // this.requestList();
      // this.requestAmount();
    },
    methods: {
      dealData,
      /**
       * 列表查询
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        reportforms
          .getPublicPileList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 统计查询
       */
      requestAmount() {
        const reqParams = {
          ...this.searchParams,
        };
        reportforms
          .getPublicPileAmount(reqParams)
          .then(res => {
            this.amountInfo = res;
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 导出
       */
      doExportFile() {
        if (this.searchParams.times && this.searchParams.times.length) {
          [this.searchParams.startTime, this.searchParams.endTime] = this.searchParams.times;
        } else {
          this.$message.warning('请选择记账日期后进行导出');
          return;
        }

        offlineExport(
          {
            downloadType: 'statistics_account_balance_change_export',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },

      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        console.log('searchData++++++', searchData);
        if (searchData.times && searchData.times.length) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.times;
        } else {
          this.$message.warning('请选择记账日期后进行查询');
          return;
        }
        // Object.assign(this.searchParams, searchData);
        // const params = cloneDeep(this.searchParams);
        // delete params.times;
        // const flag = this.isAllValuesEmpty(params);
        // if (flag) {
        this.requestList();
        this.requestAmount();
        // } else {
        //   this.$message.warning('请至少输入一个查询条件');
        //   return;
        // }
      },
      /**
       * 判断obj所有key是否为空
       * */
      isAllValuesEmpty(obj) {
        let flag = false;
        Object.keys(obj).forEach(value => {
          const item = obj[value];
          if (item !== '' && item !== null && item !== undefined) {
            flag = true;
            return true;
          }
          return false;
        });
        return flag;
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .box-card {
    font-size: 12px;

    margin-bottom: 10px;
    ::v-deep .el-card__header {
      border: 0;
      padding: 10px;
      height: 0;
      box-sizing: border-box;

      @media screen and (max-width: 1366px) {
        height: 0;
      }
    }
  }
  .flexBox {
    display: flex;
    // margin-bottom: 20px;
  }
</style>
